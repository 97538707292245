@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  @include content-box;
  padding: $post-padding-xs-y $post-padding-xs-x;

  @media (min-width: $breakpoint-xs) {
    padding: $post-padding-y $post-padding-x;
  }
}

.entry-image {
  @media screen and (max-width: 751px) {
    @include full-width-image;
    margin-bottom: -$post-padding-xs-y;

    @media (min-width: $breakpoint-xs) {
      margin-bottom: -$post-padding-y;
    }

    img {
      display: block;
    }
  }

  .entry-content + & {
    margin-top: 2em;
  }

  img {
    width: 100%;
    height: auto;
  }
}
