@mixin clearfix {
  &::before,
  &::after {
    display: table;
    content: ' ';
  }

  &::after {
    clear: both;
  }
}

@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}

@mixin content-box {
  background-color: $container-bg;

  @media (min-width: $sidebar-breakpoint) {
    box-shadow: $container-shadow;
  }
}

@function map-get-next($map, $key, $fallback: false) {
  // Check if map is valid
  @if type_of($map) == map {
    // Check if key exists in map
    @if map_has_key($map, $key) {
      // Init index counter variable
      $i: 0;

      // Init key index
      $key-index: false;

      // Traverse map for key
      @each $map-key, $map-value in $map {
        // Update index
        $i: $i + 1;

        // If map key found, set key index
        @if $map-key == $key {
          $key-index: $i;
        }

        // If next index return next value
        @if $i == $key-index + 1 {
          @return $map-value;
        }

        // If last entry return false
        @if $i == length($map) {
          @return $fallback;
        }
      }

      @warn 'No next map item for key #{$key}';
      @return $fallback;
    }

    @warn 'No valid key #{$key} in map';
    @return $fallback;
  }

  @warn 'No valid map';
  @return $fallback;
}

@mixin full-width-image {
  margin-left: -$post-padding-xs-x;
  margin-right: -$post-padding-xs-x;

  @media (min-width: $breakpoint-xs) {
    margin-left: -$post-padding-x;
    margin-right: -$post-padding-x;
  }
}
