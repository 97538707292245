// Colors
$color-gray: #a1a4a8;
$color-light-blue: #6e9ab5;
$color-dark-blue: #354f88;
$color-dark-gray: #464846;
$color-brown: #343c27;
$color-aqua: #627989;
$color-light-cream: #d1ccc6;

$text-color: #464846;
$headings-color: $color-brown;
$link-color: #4f759b;
$header-text-color: #616466;

$page-bg: #f2f2f2;
$container-bg: #fff;
$container-shadow: 0 1px 1px rgba(#000, 0.3);

$body-font-size: 1.1rem;
$body-line-height: 1.6;

$gutter-width-x: 2rem;
$gutter-width: $gutter-width-x;
$gutter-width-y: $gutter-width-x;

$nav-bar-height: 3rem;
$nav-bar-font-size: 0.9rem;
$nav-bar-line-height: 1;
$nav-bar-bg-color: darken($page-bg, 2.5%);
$nav-bar-bg-opacity: 0.975;
$nav-bar-bg: rgba($nav-bar-bg-color, $nav-bar-bg-opacity);
$nav-bar-border-color: darken($nav-bar-bg-color, 10%);

$body-bg: mix($page-bg, $nav-bar-bg-color, $nav-bar-bg-opacity);

$footer-margin: 10rem;
$footer-margin-sm: 5rem;

$sidebar-breakpoint: 50rem;
$breakpoint-xs: 30rem;
$nav-bar-breakpoint: 751px;

$container-width-base: 85%;
$container-max-width: 87.5rem;
$container-min-width: ($sidebar-breakpoint - ($gutter-width-x * 2));
$container-breakpoint: $sidebar-breakpoint;

$min-box-width: 15rem;
$two-up-breakpoint: ($min-box-width * 2) + ($gutter-width * 3);

// Forms
$input-padding-top: 0.4em;
$input-padding-bottom: 0.2em;
$input-padding-x: 0.5em;
$input-border-width: 0;
$input-border-color: #ccc;

$input-focus-color: $color-dark-gray;
$input-focus-border-color: $color-light-blue;

$button-color: $color-light-blue;
$button-border-color: darken($button-color, 5%);

$state-success-text: #3c763d;
$state-success-bg: #dff0d8;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text: #31708f;
$state-info-bg: #d9edf7;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text: #a94442;
$state-danger-bg: #f2dede;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%);

$post-padding-x: 3rem;
$post-padding-y: 2.5rem;

$post-padding-xs-x: 2rem;
$post-padding-xs-y: 1.5rem;

$sidebar-box-padding-x: 1rem;
$sidebar-box-padding-y: 0.75rem;
